export const IMAGE_CONTAINER_STYLE = {
    height: 'inherit',
    width: 'inherit'
};

export const IMAGE_SELECTION_CONTAINER_STYLE = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export const IMAGE_UPLOAD_OPTIONS_CONTAINER_STYLE = {
    textAlign: 'center'
};

export const IMAGE_UPLOAD_OPTION_BUTTON_STYLE = {
    margin: 'auto 5px'
};

export const ACTION_BUTTON_STYLE = {
    margin: 'auto 5px',
    float: 'right'
};

export const PREVIEW_IMAGE_CONTAINER_STYLE = {
    height: '120px',
    width: '120px',
    margin: 'auto 5px',
    backgroundColor: 'rgb(128 128 128 / 67%)'
};

export const IMAGE_DESCRIPTION_STYLE = {
    margin: 'auto 5px'
};

export const MIDDLE_ROW_CONTAINER_STYLE = {
    margin: '20px auto'
};

export const BG_ADD_IMAGE_STYLE = {
    height: '100%',
    width: '100%',
    opacity: 0.3
};
