
export const ERROR_BOUNDARY_CONTAINER_STYLE = {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    right: '0',
    left: '0',
    top: '0',
    bottom: '0',
    position: 'absolute',
    backgroundColor: 'rgb(245, 245, 245)'
};
