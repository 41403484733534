export const LOGIN_CREDS = [
    { userName: 's111', password: 's111' },
    { userName: 's222', password: 's222' },
    { userName: 's333', password: 's333' },
    { userName: 's444', password: 's444' },
    { userName: 's555', password: 's555' },
    { userName: 's666', password: 's666' },
    { userName: 's777', password: 's111' },
    { userName: 's888', password: 's222' },
    { userName: 'a111', password: 'a111' }
];

export const dummyGroupInfos = [
    {
        name: 'devGroup',
        members: [
            { username: 's111', password: 's111' },
            { username: 's222', password: 's222' },
            { username: 's333', password: 's333' },
            { username: 's444', password: 's444' },
            { username: 's555', password: 's555' },
            { username: 's666', password: 's666' },
            { username: 's777', password: 's111' },
            { username: 's888', password: 's222' },
            { username: 'a111', password: 'a111' }
        ]
    }
];
